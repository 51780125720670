import request from "@/utils/request";

const url = "/api/recipe/recipe";

// 获取列表
export function list(params) {
  return request({
    url: `${url}/list`,
    method: "get",
    params: params,
  });
}

// 获取信息
export function info(params) {
  return request({
    url: `${url}/info`,
    method: "get",
    params: params,
  });
}
// 获取配方材料信息
export function recipeMterialInfo(params) {
  return request({
    url: `${url}/recipeMterialInfo`,
    method: "get",
    params: params,
  });
}
// 获取配方信息信息
export function recipeInfo(params) {
  return request({
    url: `${url}/recipeInfo`,
    method: "get",
    params: params,
  });
}
// 添加
export function add(params) {
  return request({
    url: `${url}/save`,
    method: "post",
    data: params,
  });
}

// 修改
export function update(params) {
  return request({
    url: `${url}/update`,
    method: "post",
    data: params,
  });
}

// 删除
export function del(params) {
  return request({
    url: `${url}/delete`,
    method: "post",
    data: params,
  });
}


// 根据产品号模糊查询获得可能的产品号
export function getFuzzyNoRecipeProductIds(params) {
  return request({
    url: `${url}/getFuzzyNoRecipeProductIds`,
    method: "get",
    params,
  });
}

// 根据产品号联想产品信息
export function queryProduct(params) {
  return request({
    url: `${url}/queryProduct`,
    method: "get",
    params,
  });
}

//材料信息下拉
export function queryMateriaInfo() {
  return request({
    url: `${url}/queryMateriaInfo`,
    method: "get",
  });
}

//固化条件信息下拉
export function querySolidify(params) {
  return request({
    url: `${url}/querySolidify`,
    method: "get",
    params,
  });
}

//固化内容
export function getSolidifyInFo(params) {
  return request({
    url: `${url}/getSolidifyInFo`,
    method: "get",
    params,
  });
}
//配方导入
export function recipeImportExcel(params) {
  return request({
    url: `${url}/recipeImportExcel`,
    method: "post",
    data: params,
  });
}
//根据材料编号获取id
export function getMaId(params) {
  return request({
    url: `${url}/getMaId`,
    method: "get",
    params,
  });
}

// product移过来的

//获取配方信息
export function selectRecipe (params) {
  return request({
      url: `${url}/selectRecipe`,
      method: 'get',
      params: params
  })
}
//获取配方信息
export function listAllSCipe (params) {
  return request({
      url: `${url}/listAllSCipe`,
      method: 'get',
      params: params
  })
}

// 获取配方是否关联订单
export function isRecipeOrder(params) {
  return request({
    url: `${url}/isRecipeOrder`,
    method: "get",
    params: params,
  });
}

//获取行业
export function selectStandardRecipeIndustry(params) {
  return request({
    url: `${url}/selectStandardRecipeIndustry`,
    method: 'post',
    params
  })
}

//获取系列
export function selectStandardRecipeSeries(params) {
  return request({
    url: `${url}/selectStandardRecipeSeries`,
    method: 'post',
    params
  })
}

//获取表面
export function selectStandardRecipeSurface(params) {
  return request({
    url: `${url}/selectStandardRecipeSurface`,
    method: 'post',
    params
  })
}

//获取光泽
export function selectStandardRecipeGloss(params) {
  return request({
    url: `${url}/selectStandardRecipeGloss`,
    method: 'post',
    params
  })
}

//获取列表数据
export function queryStandardRecipeList(data) {
  return request({
    url: `${url}/queryStandardRecipeList`,
    method: 'post',
    data:data
  })
}

//获取光泽
export function excelTemplateHandle() {
  return request({
    url: `/api/sample/exportRecipeDataTemplate`,
    method: 'post',
    responseType: "blob"
  })
}

// 导入
export function importStandardRecipeExcelData(params) {
  return request({
      url: '/api/sample/importStandardRecipeExcelData',
      method: 'post',
      data: params
  })
}

export function saveReferenceRecipe(params) {
  return request({
      url: '/api/sample/saveReferenceRecipe',
      method: 'post',
      data: params
  })
}

export function queryRecipeInfo(params) {
  return request({
      url: '/api/sample/queryRecipeInfo',
      method: 'post',
      params
  })
}

export function queryStandardRecipeInfo(params) {
  return request({
      url: '/api/sample/queryStandardRecipeInfo',
      method: 'post',
      params
  })
}

export function decideIsOneOrDoubleComponent(params) {
  return request({
      url: '/api/sample/decideIsOneOrDoubleComponent',
      method: 'post',
      params
  })
}

//获取光泽
export function selectStandardRecipeVersionNumber(params) {
  return request({
    url: `${url}/selectStandardRecipeVersionNumber`,
    method: 'post',
    params
  })
}